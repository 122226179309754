import { TableDefinition } from '../../models';
import { BatchJob, JobDetails } from '../../models/batch-job';

export const BATCH_JOB_TABLE_DEFINITION: TableDefinition = [
  {
    key: 'API.BatchJob.id',
    prop: 'uuid',
    visible: false,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.startDate',
    prop: 'startedAt',
    visible: true,
    dateFormat: 'y-MM-dd HH:mm',
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.name',
    prop: 'name',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.startedBy',
    prop: 'userName',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.progress',
    prop: 'progress',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'NUMBER',
    transform: (value: any) => {
      const progress = typeof value === 'number' ? value : 0;
      return `${progress}%`;
    },
  },
  {
    key: 'API.BatchJob.message',
    prop: 'message',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
  },
  {
    key: 'API.BatchJob.status',
    prop: 'status',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
    transform: (value: any) => String(value || ''),
  },
  {
    key: 'API.BatchJob.processed',
    prop: 'parsedDetails',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'STRING',
    transform: (value: any) => {
      if (!value || typeof value !== 'object') return '';
      const details = value as JobDetails;
      const total = details.total || 0;
      const processed = details.processed || 0;
      // const timeleft = total < processed ? '' : (details.timeLeft ? `/ ${Math.floor(details.timeLeft / 60) > 0 ? `${Math.floor(details.timeLeft / 60)} h ` : ''}${details.timeLeft % 60} m` : '');
      return `${total}/${processed}`;
    },
  },
  {
    key: 'API.BatchJob.errors',
    prop: 'errorCount',
    visible: true,
    objectName: 'BatchJob',
    searchFieldType: 'NUMBER',
    transform: (value: any) => String(value || 0),
  }
];
