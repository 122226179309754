import { DataListViewComponent } from './data-list-view/data-list-view.component';
import { LayoutComponent } from './layout/layout.component';
import { TimezoneModalComponent } from './timezone-modal/timezone-modal.component';

export const entryComponents = [
    TimezoneModalComponent,
];
export const components = [
    ...entryComponents,
    LayoutComponent,
    DataListViewComponent];

export * from './data-list-view/data-list-view.component';
export * from './layout/layout.component';
export * from './timezone-modal/timezone-modal.component';
export * from './discard-changes-modal/discard-changes-modal.component';

