import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaLibraryService {
  private apiUrl = '/admin/rest/v2/mediaassets';

  constructor(private http: HttpClient) {}

  getMediaLibraryData(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}`, { params });
  }

  getFullAssetUrl(assetUrl: string): string {
    return `/mediaassets/${assetUrl}`;
  }

  getBaseAssetUrl(assetUrl: string): string {
    return `${window.location.origin}/mediaassets/${assetUrl}`;
  }

  generateTranscript(url: string): Observable<any> {
    const hostname = window.location.hostname;
    const tenantName = hostname.split('.')[0];

    const params = {
      persist: true,
      videoFile: url,
      tenantName,
      overwrite: true,
      output: false
    };

    return this.http.get(`/ai/api/1/speechtotext`, { params });
  }

  deleteAssets(assetIds: number[]): Observable<any> {
    return this.http.delete(`${this.apiUrl}`, {
      body: { assetIds }
    });
  }

  generateReport(assetIds: number[]): Observable<string> {
    return this.http.post('/admin/rest/v2/report',
      { assetIds },
      { responseType: 'text' }
    );
  }

  fetchFileContent(url: string): Observable<string> {
    return this.http.get(url, {
      responseType: 'text',
      headers: new HttpHeaders().set('Cache-Control', 'no-cache')
    });
  }
}
