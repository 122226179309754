import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CmUiConfirmComponent, CmUiFileImportModalComponent, CmUiSnackBarService } from '@cm/ui-modules';
import { FrontendTranslateService } from '@shared/services/translation.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalTranslationKeys, ObjectName } from '../models';

@Injectable({'providedIn':'root'})
export class DataListService {
  constructor(private snackBar: CmUiSnackBarService, private translate: FrontendTranslateService ,private dialog: MatDialog) {}

  showNotification(translationKey: string): void {
    this.snackBar.showNotification(this.translate.instant(translationKey))

    // this.snackBar.openFromComponent(SnackBarComponent, {
    //   data: { translationKey },
    //   duration: 3000,
    //   verticalPosition: 'top',
    //   horizontalPosition: 'right',
    // });
  }

  showConfirmDialog(translationKeys: ModalTranslationKeys): Observable<boolean> {
    return this.dialog
      .open(CmUiConfirmComponent, { data: { translationKeys }, panelClass: 'smallPanel' })
      .afterClosed()
      .pipe(filter((confirmFlag) => !!confirmFlag));
  }

  setPaginationToSession(pageSize: number, pageIndex: number, objectName: ObjectName): void {
    localStorage.setItem(`${objectName}_pageSize`, `${pageSize}`);
    localStorage.setItem(`${objectName}_pageIndex`, `${pageIndex}`);
  }

  showImportFileDialog(): Observable<{ status: 'error' | 'success' | 'cancel'; error?: any }> {
    return this.dialog
      .open(CmUiFileImportModalComponent, {
        width: '500px',
        maxWidth: '100%',
        panelClass: 'smallPanel',
      })
      .afterClosed()
      .pipe(filter((confirmFlag) => !!confirmFlag));
  }


}
